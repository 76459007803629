import React, {useEffect} from 'react'
import '../common.css'
import './bookAnAuction.css'
import Navbar from './navbar'
import mixpanel from 'mixpanel-browser';

export default function BookAnAuction() {

  useEffect(() => {
    mixpanel.init('e4e951e9dca6e1f09ed907de53057dbe'); 

    var curDate = new Date()
    
    // Track
    mixpanel.track('Page Visited', {
      'Page': 'Book An Auction',
      'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
    });
  }, []);

  return (
    <div className='backgroundBanner2'>
      {/* Navigation Bar */}
      <Navbar></Navbar>

      <div className="sectionBox">
        <img className='iconFormat' src={require('../images/checklist.png')} />
        <div className="pageHeader">HOW TO BOOK AN AUCTION</div>

        <div className="pageText"> Our passion is to offer our clients the best options and suggestions to have a successful auction. We offer Live, Live and Online and Online Only auctions. </div>

        <div className="pageText"> The best way to book an auction is to contact Byron at <strong>320.291.9566</strong>. We give a free consultation for what we can offer and suggestions for an optimal outcome.  </div>

        <div className="pageText"> Selling Real Estate by auction is a very unique alternative to the conventional method. We can't express enough the advantages to selling Real Estate at auction.  </div>

        <div className="pageText">We have a great team that handles the process from beginning to end with you.</div>
      </div>




    </div>
  )
}