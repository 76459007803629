import React from 'react'
import PortalPicture from './portalPicture.js'

export default function PortalPictureList({listings, remove, picDescription}) {
    return (
        listings.map(listing => {
            return  <PortalPicture key={listing.id} entry={listing} remove={remove} picDescription={picDescription}/>
        })
    )
}
