import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from './navbar'
import mixpanel from 'mixpanel-browser';



import './home.css'

export default function Home() {

  mixpanel.init('e4e951e9dca6e1f09ed907de53057dbe');

  var errorMsg = useRef()
  var success = useRef()

  var emailErrorMsg = useRef()
  var emailSuccess = useRef()

  var phoneNumber = useRef()
  var emailAddress = useRef()

  const [bannerDisplay, setBannerDisplay] = useState('none')
  const [bannerTitle, setBannerTitle] = useState('')
  const [bannerDescription, setBannerDescription] = useState('')

  useEffect(() => {

    var curDate = new Date()

    // Track
    mixpanel.track('Page Visited', {
      'Page': 'Home',
      'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
    });

  }, []);

  useEffect(() => {
    getBanner()
  }, [])


function getBanner(){


  fetch("https://auctionsbyrobyn.xyz:5005/getBanner", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
    })
  }).then((response) => response.json())
    .then(response => {

      if(response.status === 'none'){
        setBannerDisplay('none')
      } else {
        setBannerTitle(response.bannerTitle)
        setBannerDescription(response.bannerDescription)
        setBannerDisplay('block')
      }
    })
}



  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  function phone(inputtxt) {
    var phoneno = /^\d{10}$/;
    if (inputtxt.match(phoneno)) { return true; }
    else { 
      console.log("Issue with phone number")
      return false; 
    }
  }

  ////////////////////////////////////////////////////////////
  // Text Notifications
  ////////////////////////////////////////////////////////////
  async function notificationSignup() {
    console.log("Notification button pressed!")

    var curDate = new Date()


    // Track
    mixpanel.track('Sign Up', {
      'Page': "Home",
      'Signup status': true,
      'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
    });


    if (phone(phoneNumber.current.value)) {

      var returnData = await fetch("https://auctionsbyrobyn.xyz:5005/getNotified", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phoneNumber: phoneNumber.current.value
        })
      }).then((response) => response.json())
        .then(async response => {
          console.log(response)

          return response
        })

      success.current.style.display = 'block'

    } else {
      console.log("Issue with phone number here.")
      errorMsg.current.style.display = 'block'
      await timeout(3000)
      errorMsg.current.style.display = 'none'
    }
  }

  function ValidateEmail(email) {

    var emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  
    if (!email)
        return false;

    if(email.length>254)
        return false;

    var valid = emailRegex.test(email);
    if(!valid)
        return false;

    // Further checking of some things regex can't handle
    var parts = email.split("@");
    if(parts[0].length>64)
        return false;

    var domainParts = parts[1].split(".");
    if(domainParts.some(function(part) { return part.length>63; }))
        return false;

    return true;
  }

  ////////////////////////////////////////////////////////////
  // Email Notifications
  ////////////////////////////////////////////////////////////
  async function emailSignup() {
    console.log("Email button pressed!")

    var curDate = new Date()

    // Track
    mixpanel.track('Sign Up', {
      'Page': "Home",
      'Signup status': true,
      'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
    });



    if (ValidateEmail(emailAddress.current.value)) {
      console.log("Valid email")

      var returnData = await fetch("https://auctionsbyrobyn.xyz:5005/getNotifiedEmail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          emailAddress: emailAddress.current.value
        })
      }).then((response) => response.json())
        .then(async response => {
          console.log(response)

          return response
        })

      emailSuccess.current.style.display = 'block'

    } else {
      console.log("Invalid email")
      emailErrorMsg.current.style.display = 'block'
      await timeout(3000)
      emailErrorMsg.current.style.display = 'none'
    }
  }

  function closeSuccess() {
    phoneNumber.current.value = ''
    success.current.style.display = 'none'
  }

  function closeEmailSuccess() {
    emailAddress.current.value = ''
    emailSuccess.current.style.display = 'none'
  }


  return (
    <div>
      {/* Navigation Bar */}
      <Navbar></Navbar>
      {/* Banner */}
      <div className='backgroundBanner'>
        <div>

          {/* Banner */}
          <div className='signupBox' style={{display: bannerDisplay}}>
              <div className='bannerTitle'>{bannerTitle}</div>
              <div className='bannerDescription'>{bannerDescription}</div>
          </div>

          {/* General information */}
          <div className='signupBox'>
            <div className='whoWeAreHeader'>Auctions by Robyn</div>

            <div className='whoWeAreText'>We are your Licensed & Bonded Professional Auction Team that will go the extra mile to meet all of your needs. We have decades of combined experience in Real Estate, Farms, Livestock, Guns, Heavy Equipment and Personal Property.</div>

            <div className='whoWeAreSectionHeader'>Why should I hire Auctions by Robyn?</div>

            <ul className='whoWeAreBullets'>
              <li>Competitive Commission Rates</li>
              <li>Flexible scheduling - you choose the date that works for you</li>
              <li>Caring and professional team</li>
              <li>Competitive bidding - everyone has an opportunity to bid on your items</li>
              <li>References for professionals that can help walk you through many legal issues such as Power of Attorney, Wills and Real Estate closings</li>
              <li>Certified Auctioneer and Certified Personal Property Appraiser</li>
            </ul>

            <div className='whoWeAreSectionHeader'>We offer:</div>

            <ul className='whoWeAreBullets'>
              <li>Full service auction company providing setup, clerking, advertising, and clean up..</li>
              <li>Live On Site or Off Site auctions with Online options and Online Only.</li>
              <li>We have Auction Toppers, Portable Sound System, Clerking Trailer, Corral Panels & Gates, Display wagons and flats, Skid Steer, Pressure washer, tarps and much more.</li>
              <li>Effective Marketing Plan with internet, newspapers and widely distributed sale bills.</li>
            </ul>

            <div className='whoWeAreText'>For a free consultation please call Byron at 320-291-9566</div>
          </div>




          {/* Text notification */}
          <div className='signupBox'>
            <div className='signupNotification'></div>
            <div className='signupHeader'>Sign Up For Auction Text Notifications</div>
            <p className='signupText'>Receive updates via text message for upcoming auctions. Please enter your cell phone number below <strong className="noDashes">No Dashes</strong>.</p>
            <div className='errorMessage' ref={errorMsg} style={{ display: 'none' }}>Please enter your 10 digit phone Number (ex. 5551234567)</div>
            <input ref={phoneNumber} className='signupInput' type="text" name="name" placeholder='ex. 5551234567'></input>
            <button style={{ display: 'block' }} className='signupButton' value="Submit" onClick={notificationSignup}>Receive Text Notifications</button>
          </div>

          {/* Email notification */}
          <div className='signupBox'>
            <div className='emailNotification'></div>
            <div className='signupHeader'>Sign Up For Auction Email Notifications</div>
            <p className='signupText'>Receive updates via emails for upcoming auctions. Please enter your email address below.</p>
            <div className='errorMessage' ref={emailErrorMsg} style={{ display: 'none' }}>Please enter your email address</div>
            <input ref={emailAddress} className='signupInput' type="text" name="name" placeholder='ex. johndoe@gmail.com'></input>
            <button style={{ display: 'block' }} className='signupButton' value="Submit" onClick={emailSignup}>Receive Email Notifications</button>
          </div>

        </div>
      </div>

      {/* Text Success */}
      <div ref={success} className='overlay' style={{ display: 'none' }}>
        <div className='notificationSuccess'>
          <div className='notificationHeader'>You have been successfully added!</div>
          <div className='notificationInfo'>You will now recieve a text notification confirming your subscription enrollment. If you would like to quit the subscription, please reply STOP and you will no longer recieve future notifications.</div>
          <button className='signupButton' onClick={closeSuccess}>Close</button>
        </div>
      </div>

      {/* Email success */}
      <div ref={emailSuccess} className='overlay' style={{ display: 'none' }}>
        <div className='notificationSuccess'>
          <div className='notificationHeader'>You have been successfully added!</div>
          <div className='notificationInfo'>You will now recieve an email notification confirming your subscription enrollment. Please note that your notifications may be located in the "Spam Folder". If you would like to quit the subscription, please use the unsubscribe button in the email at the bottom and you will no longer recieve future notifications.</div>
          <button className='signupButton' onClick={closeEmailSuccess}>Close</button>
        </div>
      </div>


    </div>
  )
}
