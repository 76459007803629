import './App.css';
import React from 'react';
import navbar from "./components/navbar"
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


import Home from './components/home.js'
import UpcomingAuctions from './components/upcomingAuctions.js'
import BookAnAuction from './components/bookAnAuction.js'
import Contact from './components/contact.js'
import Help from './components/help.js'
import PortalLogin from './components/portalLogin.js'

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />}></Route>
          <Route path="/upcomingAuctions" element={<UpcomingAuctions />}></Route>
          <Route path="/bookAnAuction" element={<BookAnAuction />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/portal" element={<PortalLogin />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
