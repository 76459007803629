import React from 'react'
import { Link } from 'react-router-dom';
import '../common.css'
import './navbar.css'


export default function navbar() {

    



    return (
        <div className='scrolled'>
            {/* navbar*/}

            <div className="logoBox">
            <img style={{display:"block"}} className='logoFormat' src={require('../images/auctionsbyrobyn2023.png')} />
            </div>
            

            <div className='navBar'>
                <Link  className="navButton" to="/">
                    <div className="navLink">Home</div>
                </Link>
                <Link className="navButton" to="/upcomingAuctions">
                    <div className="navLink">Upcoming Auctions</div>
                </Link>
                <Link  className="navButton" to="/bookAnAuction">
                    <div className="navLink">How To Book An Auction</div>
                </Link>
                <Link  className="navButton" to="/contact">
                    <div className="navLink">Contact</div>
                </Link>

                <Link  className="navButton" to="/portal">
                    <div className="navLink">Employee Portal</div>
                </Link>
            </div>
            

        </div>
    )
}
