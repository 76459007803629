import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './portalLogin.css'

import PortalDashboard from './portalDashboard.js'

export default function PortalLogin() {

  var [disable, setDisable] = useState(false)
  var [portalDisplay, setPortalDisplay] = useState('none')
  var [loginDisplay, setLoginDisplay] = useState('block')

  var errorRef = useRef()
  var username = useRef()
  var password = useRef()

  var [logged, setLogged] = useState(false)

  // Should I reload login status?
  useEffect(() => {

    if(logged){
      username.current.value = ''
      password.current.value = ''
    } else {
      username.current.value = ''
      password.current.value = ''

      const sessionToken = sessionStorage.getItem('token');

      if(sessionToken == 'unauthorized' || sessionToken == null || sessionToken == '') {
        setPortalDisplay('none')
        setLoginDisplay('block')
      } else {
        setLoginDisplay('none')
        setPortalDisplay('block')
      }
    }
  }, [logged]);

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

  ///////////////////////////////////////////////////////////////////////
  // Log in to dashboard
  ///////////////////////////////////////////////////////////////////////
  async function goToMain(e) {
    setDisable(true)
    e.preventDefault();

    await fetch("https://auctionsbyrobyn.xyz:5005/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username.current.value,
        password: password.current.value
      })
    }).then((response) => response.json())
      .then(response => {

        if(response.status == 'good'){
          sessionStorage.setItem('token', response.token);
          setLoginDisplay('none')
          setPortalDisplay('block')

        } else {
          sessionStorage.setItem('token', 'unauthorized');
          setPortalDisplay('none')
          setLoginDisplay('block')
        }
      })


      setDisable(false)
  }

  ///////////////////////////////////////////////////////////////////////
  // Log out
  ///////////////////////////////////////////////////////////////////////
  function logout() {
    setDisable(true)
    sessionStorage.setItem('token', 'unauthorized');
    setPortalDisplay('none')
    setLoginDisplay('block')
    setLogged(false)
    // Remove local credentials/ID
    setDisable(false)
  }


  return (
    <div>
      {/* Navigation Bar */}

      <div style={{ display: loginDisplay }}>
        <div className="loginBox">
          <img style={{ display: "block", margin: '0 auto' }} className='logoFormat' src={require('../images/auctionsbyrobyn2023.png')} />
          <div style={{ marginTop: '1rem', fontWeight: 'bold', fontSize: '16pt' }}>Portal Login</div>
          <div ref={errorRef} className='errorMessage' style={{display: 'none'}}>Username or Password is incorrect</div>
          <label className="inputText">
            <input ref={username} style={{ fontSize: '16pt' }} type="text" name="username" placeholder='Username'></input>
          </label>
          <label className="inputText">
            <input ref={password} style={{ fontSize: '16pt' }} type="password" name="password" placeholder='Password'></input>
          </label>
          <div className="loginButton" disabled={disable} onClick={goToMain}>Log In</div>
          <Link className="loginButton" to="/">
            <div >Return to home</div>
          </Link>

        </div>
      </div>


      <PortalDashboard logged={logout} portalDisplay={portalDisplay}></PortalDashboard>


    </div>
  )
}