import React, {useEffect} from 'react'
import './contact.css'
import Navbar from './navbar'
import mixpanel from 'mixpanel-browser';

export default function Contact() {

  useEffect(() => {
    mixpanel.init('e4e951e9dca6e1f09ed907de53057dbe'); 

    var curDate = new Date()
    
    // Track
    mixpanel.track('Page Visited', {
      'Page': 'Contact',
      'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
    });
  }, []);

  return (
    <div className='backgroundBanner2'>
      {/* Navigation Bar */}
      <Navbar></Navbar>
      <div className='sectionBox'>
      <img className='iconFormat' src={require('../images/gavel.png')} />
        <div className='contactHeader'>Auction Booking/Auctioneers</div>
        <div className='generalText'> All inquiries pertaining to booking an auction or auctioneer relavant questions - please call the following:</div>
        <div className='contactName'>Byron Robyn :  <a className='contactPhone' href="tel: 320-291-9566">320.291.9566</a></div>
      </div>

      <div className='sectionBox'>
      <img className='iconFormat' src={require('../images/support.png')} />
        <div className='contactHeader'>
          Support/Help Line
        </div>
        <div className='generalText'> All questions pertaining the use of Proxibid.com, logistics, or general questions - please call the following:</div>
        <div className='contactName'>Denise Robyn : <a className='contactPhone' href="tel: 320-291-1333">320.291.1333 </a></div>
      </div>
    </div>
  )
}