import React from 'react'
import './portalPicture.css'

export default function PortalPicture({ entry, remove, picDescription}) {

    function addDescription(event){
        console.log(event.target.value)
        picDescription(entry.id, event.target.value)
    }


    function deleteEntry(event){
        remove(entry.id)
    }

    return (

        <div className="pictureListing">
            <div className='deleteButton' onClick={deleteEntry}>Delete</div>
            <div className='pictureName'>{entry.selectedFile.name}</div>
            <input maxLength="150" type='text' onBlur={addDescription} placeholder='Description' className='descriptionInput' value={entry.description}></input>
        </div>

    )
}
