import React from 'react'
import { Link } from 'react-router-dom';
import '../common.css'
import PortalListing from './portalListing.js'

export default function PortalList({listing, deleteListing, disable}) {
    return (
        listing.map(listing => {
            return  <PortalListing key={listing.id} listing={listing} deleteListing={deleteListing} disable={disable}></PortalListing>
        })
    )
}
