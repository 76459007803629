import React from 'react'
import './confirmation.css'

export default function Confirmation({display, submit, cancel}) {


  return (
    <div className='overlay' style={{display: display}}>
      <div className='notificationSuccess'>
        <div className='notificationHeader'>Confirm Posting</div>
        <div className='notificationInfo'>Are you sure you want to post? Please be sure the bill is the first picture uploaded and all edits are correct. This post will be broadcasted out to the subscription list.</div>
        <button className='signupButton' onClick={submit}>POST</button>
        <button className='signupButton' onClick={cancel}>CANCEL</button>
      </div>
    </div>
  )
}