import React from 'react'
import { Link } from 'react-router-dom';
import '../common.css'
import './portalListing.css'

export default function PortalListing({listing, deleteListing, disable}) {
    function deleteEntry(){
        deleteListing(listing.id, listing._id)
    }

    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    function ordinal(num) {
        const lastDigit = num % 10;
        const lastTwoDigits = num % 100;
      
        return num + (
          // 11-13 take 'th'
          (lastTwoDigits > 9 && lastTwoDigits < 14) ? 'th' :
          lastDigit === 1 ? 'st' :
          lastDigit === 2 ? 'nd' :
          lastDigit === 3 ? 'rd' : 'th'
        )
      }

    return (
        <div className='gridListing'>
            <button className='deleteButton' onClick={deleteEntry} disabled={disable}>Delete</button>
            <div>{day[listing.day] + ', ' + month[listing.month] + ' ' + ordinal(listing.date) + ' ' + listing.year}</div>
            <div>{listing.auctionTime}</div>
            <div>{listing.auctionTitle}</div>
            <div>{listing.auctionDescription}</div>
        </div>
    )
}
