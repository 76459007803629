import React from 'react'
import '../common.css'
import Navbar from './navbar'

export default function Help() {
  return (
    <div>
      {/* Navigation Bar */}
      <Navbar></Navbar>
      <div>HELP NEW</div>
    </div>
  )
}