import React from 'react'
import './confirmation.css'

export default function ConfirmBanner({display, submit, cancel}) {

  return (
    <div className='overlay' style={{display: display}}>
      <div className='notificationSuccess'>
        <div className='notificationHeader'>Confirm Banner</div>
        <div className='notificationInfo'>Are you sure you want to post this banner? Please be sure the Title and Description are set and all edits are correct. This post will be broadcasted out to everyone on the subscription list.</div>
        <button className='signupButton' onClick={submit}>POST</button>
        <button className='signupButton' onClick={cancel}>CANCEL</button>
      </div>
    </div>
  )
}