import React, { useRef, useState, useEffect } from 'react'
import '../common.css'
import './home.css'
import Navbar from './navbar'
import './upcomingAuctions.css'
import AuctionList from './auctionList'
import mixpanel from 'mixpanel-browser';

export default function UpcomingAuctions() {

  var [currentListings, setCurrentListings] = useState([])
  var [noListings, setNoListings] = useState('none')

  useEffect(() => {
    loadListings()

    mixpanel.init('e4e951e9dca6e1f09ed907de53057dbe'); 

    var curDate = new Date()
    
    // Track
    mixpanel.track('Page Visited', {
      'Page': 'Upcoming Auctions',
      'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
    });
  }, []);

  async function loadListings() {

    console.log("loading")
    var returnData = await fetch("https://auctionsbyrobyn.xyz:5005/getListings", {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({
        // userID: userID,
      })
    }).then((response) => response.json())
      .then(async response => {
        
        for(let i = 0; i < response.length; i++) {
          var info = new Date(response[i].auctionDate)
          var endInfo = new Date(response[i].expirationDate)
    
          response[i].day = info.getDay()
          response[i].month = info.getMonth()
          response[i].date = info.getDate()
          response[i].year = info.getFullYear()

          response[i].endDay = endInfo.getDay()
          response[i].endMonth = endInfo.getMonth()
          response[i].endDate = endInfo.getDate()
          response[i].endYear = endInfo.getFullYear()

          response[i].id = i;
          response[i].currentPhoto = 1;
          response[i].listingView = 'block'
          response[i].picView = 'none'

          if(response[i].imageCount == 1){
            response[i].showPicButton = 'none'
          } else {
            response[i].showPicButton = 'block'
          }

         }
        return response
      })

      if(returnData.length == 0){
        setNoListings('block')
      } else {
        setCurrentListings(returnData)
      }
  }

  function openPictureView(e, id){
    e.preventDefault()
    var listing = currentListings.slice()
    listing[id].listingView = 'none'
    listing[id].picView = 'block'
    setCurrentListings(listing)
  }

  function closePictureView(e, id){
    e.preventDefault()
    var listing = currentListings.slice()
    listing[id].listingView = 'block'
    listing[id].picView = 'none'
    setCurrentListings(listing)
  }


  function prevImage(e, id){
    console.log("previous")
    e.preventDefault()
    var listing = currentListings.slice()

    if(listing[id].currentPhoto > 1) {
      listing[id].currentPhoto = listing[id].currentPhoto - 1
    } else {
      listing[id].currentPhoto = listing[id].imageCount - 1;
    }
    setCurrentListings(listing)
  }


  function nextImage(e, id){
    console.log("here")
    e.preventDefault()
    var listing = currentListings.slice()

    if(listing[id].currentPhoto < listing[id].imageCount - 1) {
      listing[id].currentPhoto = listing[id].currentPhoto + 1
    } else {
      
      listing[id].currentPhoto = 1;
    }
    setCurrentListings(listing)

  }


  return (
    <div className='backgroundBanner2'>
      <div >
        {/* Navigation Bar */}
        <Navbar></Navbar>
        <div className='spacer'></div>
        <AuctionList listing={currentListings} nextImage={nextImage} prevImage={prevImage} closePic={closePictureView} openPic={openPictureView}></AuctionList>
      </div>
      <div style={{display: noListings}} className='signupBox'>
        <img className='noListingPic' src={require('../images/noListing.png')} />
        <div className='noListings'>No Listings at this time - Please check again at a later date.</div>
      </div>

    </div>
  )
}
