import React, { useRef } from 'react'
import './auctionListing.css'
import mixpanel from 'mixpanel-browser';



export default function AuctionListing({ listing, nextImage, prevImage, openPic, closePic }) {

    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const pictureView = useRef()
    const listingView = useRef()

    mixpanel.init('e4e951e9dca6e1f09ed907de53057dbe');

    function nextPhoto(e) {
        nextImage(e, listing.id)
    }

    function prevPhoto(e) {
        prevImage(e, listing.id)
    }

    function proxiClick(e) {
        var curDate = new Date()

        // Track
        mixpanel.track('Listing Visited', {
            'Page': 'Upcoming Auctions',
            'Listing Title': listing.auctionTitle,
            'Action': 'Redirection to ProxiBid',
            'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
        });
    }

    const onClickUrl = (url) => {
        return () => openInNewTab(url)
      }

      const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    function openPictureView(e) {
        var curDate = new Date()

        // Track
        mixpanel.track('Listing Visited', {
            'Page': 'Upcoming Auctions',
            'Listing Title': listing.auctionTitle,
            'Action': 'View more pictures',
            'Date': curDate.getMonth() + '/' + curDate.getDay() + '/' + curDate.getFullYear(),
        });
        openPic(e, listing.id)
    }

    function closePictureView(e) {
        closePic(e, listing.id)
    }

    function ordinal(num) {
        const lastDigit = num % 10;
        const lastTwoDigits = num % 100;

        return num + (
            // 11-13 take 'th'
            (lastTwoDigits > 9 && lastTwoDigits < 14) ? 'th' :
                lastDigit === 1 ? 'st' :
                    lastDigit === 2 ? 'nd' :
                        lastDigit === 3 ? 'rd' : 'th'
        )
    }

    return (
        <div>
            <div ref={pictureView} className='overlay' style={{ display: listing.picView }}>

                <div className='pictureViewText'>
                    {listing.imageDescription[listing.currentPhoto]}
                </div>
                <div >
                    <img className='pictureView' src={listing.imageURL[listing.currentPhoto]}></img>
                </div>

                <div className='buttonsGrid'>
                    <div className='prevButton' onClick={prevPhoto}>Prev Photo</div>
                    <div className='nextButton' onClick={nextPhoto}>Next Photo</div>
                </div>
                <div className='closeButton' onClick={closePictureView}>Close</div>
            </div>

            <div ref={listingView} className='sectionBox' style={{ display: listing.listingView }}>


                {/* Start Date and Time */}
                <div style={{ display: listing.online ? 'block' : 'none' }} className='onlineDate'>{day[listing.day] + ', ' + month[listing.month] + ' ' + ordinal(listing.date) + ' ' + listing.year}</div>
                <div style={{ display: listing.live ? 'block' : 'none' }} className='liveDate'>{day[listing.day] + ', ' + month[listing.month] + ' ' + ordinal(listing.date) + ' ' + listing.year}</div>
                <div style={{ display: listing.liveOnline ? 'block' : 'none' }} className='liveOnlineDate'>{day[listing.day] + ', ' + month[listing.month] + ' ' + ordinal(listing.date) + ' ' + listing.year}</div>
                <div className='auctionTime'>{'Auction start time: ' + listing.auctionTime}</div>


                <div className='online' style={{ display: listing.online ? 'block' : 'none' }}>Online Only</div>
                <div className='live' style={{ display: listing.live ? 'block' : 'none' }}>Live Only</div>
                <div className='liveOnline' style={{ display: listing.liveOnline ? 'block' : 'none' }}>Live and Online</div>

                <div className='divider'></div>
                <div className='auctionHeader'>{listing.auctionTitle}</div>

                <div className='auctionDescription'>{listing.auctionDescription}</div>
                <div>
                    <img className='auctionBill' src={listing.imageURL[0]} onClick={onClickUrl(listing.imageURL[0])}></img>
                </div>
                <div className='clickBillToExpand'>Click Auction Bill to expand picture</div>

                <div className='viewPicturesButton' style={{display: listing.showPicButton}} onClick={openPictureView}>View {listing.imageCount - 1} More Pictures</div>
                <a style={{ display: listing.online ? 'block' : 'none' }} onClick={proxiClick} className='proxiOnline' href="https://www.proxibid.com/Auctions-by-Robyn-amp-Norby/auction-house/11350?cid=99999999&at=&Archived=1" target="_blank"> Go To Online Bidding at Proxibid.com</a>
                <a style={{ display: listing.liveOnline ? 'block' : 'none' }} className='proxiLiveOnline' href="https://www.proxibid.com/Auctions-by-Robyn-amp-Norby/auction-house/11350?cid=99999999&at=&Archived=1" target="_blank"> Go To Online Bidding at Proxibid.com</a>

                <a style={{ display: listing.online ? 'block' : 'none' }} className='helpButton' href="https://www.auctionsbyrobyn.com/contact"> Need help with online bidding? click here</a>
                <a style={{ display: listing.liveOnline ? 'block' : 'none' }} className='helpButton' href="https://www.auctionsbyrobyn.com/contact"> Need help with online bidding? click here</a>
            </div>
        </div>

    )
}
