import React, { useState, useRef, useEffect } from 'react'
import TimePicker from 'react-time-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../common.css'
import './portalDashboard.css'
import PortalList from './portalList.js'
import PortalPictureList from './portalPictureList.js'
import Confirmation from './confirmation.js'
import ConfirmBanner from './confirmBanner.js'

export default function PortalDashboard({ logged, portalDisplay }) {

  const [confirmPostWindow, setConfirmPostWindow] = useState('none')
  const [confirmBannerWindow, setConfirmBannerWindow] = useState('none')
  const [disable, setDisable] = useState(false)

  // Auction Start Time
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState('10:00');

  // Banner expiration
  const [expireBannerDate, setExpireBannerDate] = useState(new Date());

  // Object Array of listings
  var [currentListings, setCurrentListings] = useState([])

  // Reference to auction creation input
  const auctionType = useRef()
  const auctionTitle = useRef()
  const auctionDescription = useRef()

  // Reference to banner creation input
  const bannerTitle = useRef()
  const bannerDescription = useRef()

  // Display references
  var dashboard = useRef()
  var createListing = useRef()

  // Picture selection references
  var [selectedPicState, setSelectedPicState] = useState([])
  var [pictureCount, setPictureCount] = useState(0)

  // Subscribers
  const [emailSubs, setEmailSubs] = useState(0)
  const [textSubs, setTextSubs] = useState(0)


  useEffect(() =>  {
    if(portalDisplay == 'block'){
      loadListings()
    }

  },[portalDisplay])


  ////////////////////////////////////////////////////////////////////////////
  // Get Subscribers
  ////////////////////////////////////////////////////////////////////////////
  async function getCurrentSubscribers(){
    await fetch("https://auctionsbyrobyn.xyz:5005/getSubscribers", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: sessionStorage.getItem('token')
      })
    }).then((response) => response.json())
      .then(response => {
        console.log(response)
        setEmailSubs(response.emailCount)
        setTextSubs(response.textCount)
      })
      .catch(err => {
        exitDashboard()
      })
  }


  ////////////////////////////////////////////////////////////////////////////
  // Load current listings
  ////////////////////////////////////////////////////////////////////////////
  async function loadListings() {

    setDisable(true)
    
    console.log("Grabbing the listings!")

    var testData = await fetch("https://auctionsbyrobyn.xyz:5005/getListings", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({

      })
    }).then((response) => response.json())
      .then(response => {

        for (var i = 0; i < response.length; i++) {
          var info = new Date(response[i].auctionDate)
          response[i].id = i
          response[i].day = info.getDay()
          response[i].month = info.getMonth()
          response[i].date = info.getDate()
          response[i].year = info.getFullYear()
        }

        return response
      })
      .catch(err => {
        exitDashboard()
      })

      await getCurrentSubscribers()

    setCurrentListings(testData)
    setDisable(false)
  }




  ////////////////////////////////////////////////////////////////////////////
  // Log out and go to login screen
  ////////////////////////////////////////////////////////////////////////////
  function exitDashboard() {
    setDisable(true)
    auctionTitle.current.value = ''
    auctionDescription.current.value = ''
    setStartDate(new Date())
    onChange('10:00')
    setSelectedPicState([])
    setPictureCount(0)
    sessionStorage.clear()
    logged()
    setDisable(false)
  }

  var getFormattedTime = function (fourDigitTime) {
    var hours24 = parseInt(fourDigitTime.substring(0, 2));
    var hours = ((hours24 + 11) % 12) + 1;
    var amPm = hours24 > 11 ? 'pm' : 'am';
    var minutes = fourDigitTime.substring(2);

    return hours + minutes + amPm;
  };

  ////////////////////////////////////////////////////////////////////////////
  // Go to Create New Listing Prompt
  ////////////////////////////////////////////////////////////////////////////
  function createNewListing(e) {
    setDisable(true)
    e.preventDefault();
    dashboard.current.style.display = 'none';
    createListing.current.style.display = 'block';
    setDisable(false)
  }

  ////////////////////////////////////////////////////////////////////////////
  // Go back to main dashboard
  ////////////////////////////////////////////////////////////////////////////
  function exitNewListing(e) {
    setDisable(true)
    e.preventDefault()
    auctionTitle.current.value = ''
    auctionDescription.current.value = ''
    setStartDate(new Date())
    onChange('10:00')
    setSelectedPicState([])
    setPictureCount(0)
    console.log(createListing.current.style.display)
    createListing.current.style.display = 'none';
    dashboard.current.style.display = 'block';
    setDisable(false)
  }

  ////////////////////////////////////////////////////////////////////////////
  // Add description to image
  ////////////////////////////////////////////////////////////////////////////
  function updateImageDescription(id, description) {
    setDisable(true)
    var test = selectedPicState.slice();
    test[id].description = description
    setSelectedPicState(test)
    console.log(selectedPicState)
    setDisable(false)
  }

  ////////////////////////////////////////////////////////////////////////////
  // Handle picture file after been selected
  ////////////////////////////////////////////////////////////////////////////
  function selectPictureHandler(event) {
    setDisable(true)
    var test = selectedPicState.slice();
    test.push({ id: pictureCount, selectedFile: event.target.files[0], description: null })
    setSelectedPicState(test)
    var newNum = pictureCount;
    newNum++;
    setPictureCount(newNum);
    setDisable(false)
  }

  ////////////////////////////////////////////////////////////////////////////
  // Remove Picture from Create Listing list
  ////////////////////////////////////////////////////////////////////////////
  function removePictureHandler(whichElement) {
    setDisable(true)
    console.log(selectedPicState)
    var test = selectedPicState.slice();
    test.splice(whichElement, 1);
    var newNum = pictureCount;
    newNum--;
    setPictureCount(newNum);

    for (let i = 0; i < pictureCount - 1; i++) {
      test[i].id = i
    }

    setSelectedPicState(test)
    setDisable(false)
  }

  ////////////////////////////////////////////////////////////////////////////
  // Remove a listing
  ////////////////////////////////////////////////////////////////////////////
  async function removeListing(element, docID) {
    setDisable(true)

    var status = await fetch("https://auctionsbyrobyn.xyz:5005/deleteListing", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: sessionStorage.getItem('token'),
        _id: docID
      })
    }).then((response) => response.json())
      .then(response => {
        console.log(response)
        return response.message
      })

    console.log(status)

    if (status == 'unauthorized') {
      console.log("Error with authentication")
      exitDashboard()
    } else {

      var tempListing = currentListings.slice();
      tempListing.splice(element, 1);

      for (let i = 0; i < tempListing.length; i++) {
        tempListing[i].id = i
      }

      setCurrentListings(tempListing)
      setDisable(false)
    }
  }

  ////////////////////////////////////////////////////////////////////////////
  // Show confirm posting window promt
  ////////////////////////////////////////////////////////////////////////////
  function confirmPosting(e) {
    e.preventDefault();
    setConfirmPostWindow('block')
  }

  ////////////////////////////////////////////////////////////////////////////
  // Exit Confirm posting window
  ////////////////////////////////////////////////////////////////////////////
  function cancelPosting(e) {
    e.preventDefault();
    setConfirmPostWindow('none')
  }

  ////////////////////////////////////////////////////////////////////////////
  // Upload listing to database
  ////////////////////////////////////////////////////////////////////////////
  async function uploadListing(e) {
    setDisable(true)
    e.preventDefault();
    setConfirmPostWindow('none')

    const formData = new FormData();

    var newTime = getFormattedTime(value)

    var online = false;
    var live = false;
    var liveOnline = false;

    if (auctionType.current.value == 'live') { live = true }
    else if (auctionType.current.value == 'online') { online = true }
    else { liveOnline = true }

    console.log("Online: " + online)
    console.log("Live: " + live)
    console.log("Live Online: " + liveOnline)

    formData.append("auctionTitle", auctionTitle.current.value)
    formData.append("auctionDescription", auctionDescription.current.value)
    formData.append("auctionDate", startDate)
    formData.append("auctionTime", newTime)
    formData.append("online", online)
    formData.append("live", live)
    formData.append("liveOnline", liveOnline)
    formData.append("imageCount", pictureCount)

    for (let i = 0; i < pictureCount; i++) {
      console.log('Iteration: ' + i)
      formData.append("files", selectedPicState[i].selectedFile, selectedPicState[i].selectedFile.name)
      formData.append("description", selectedPicState[i].description)
    }

    formData.append("token", sessionStorage.getItem('token'))

    const options = {
      method: 'POST',
      body: formData,
    }

    const status = await fetch("https://auctionsbyrobyn.xyz:5005/upload_files", options
    )
      .then(res => {
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      })
      .catch((err) => ("Error occured", err));

    if (status) {
      await loadListings()
      exitNewListing(e)
    } else {
      console.log("Error with authentication")
      exitDashboard()
    }
    setDisable(false)
  }


  ////////////////////////////////////////////////////////////////////////////
  // Show confirm BANNER window promt
  ////////////////////////////////////////////////////////////////////////////
  function confirmBanner(e) {
    e.preventDefault();
    setConfirmBannerWindow('block')
  }


  ////////////////////////////////////////////////////////////////////////////
  // Exit Confirm BANNER window
  ////////////////////////////////////////////////////////////////////////////
  function cancelBanner(e) {
    e.preventDefault();
    setConfirmBannerWindow('none')
  }

  ////////////////////////////////////////////////////////////////////////////
  // Post Banner
  ////////////////////////////////////////////////////////////////////////////
  async function postBanner(e) {
    setDisable(true)
    e.preventDefault();
    setConfirmBannerWindow('none')

    if (bannerTitle.current.value == '') {
      alert("You must add a title to the banner!");
      setDisable(false)
      return
    } else if (bannerDescription.current.value == '') {
      alert("You must add a description to the banner!");
      setDisable(false)
      return
    } else {
      console.log("I am posting")

      let status = await fetch("https://auctionsbyrobyn.xyz:5005/postBanner", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: sessionStorage.getItem('token'),
          bannerTitle: bannerTitle.current.value,
          bannerDescription: bannerDescription.current.value,
          bannerExpiration: expireBannerDate
        })
      }).then((response) => response.json())
        .then(response => {
          console.log(response.status)
          return response.status

        })

        bannerTitle.current.value = ''
        bannerDescription.current.value = ''
        setExpireBannerDate(new Date())
        setDisable(false)
    }
  }


  ////////////////////////////////////////////////////////////////////////////
  // HTML
  ////////////////////////////////////////////////////////////////////////////

  return (
    <div  style={{display: portalDisplay}}>
      <div style={{ display: 'block', margin: '0 auto' }}>

        <img style={{ display: "block", margin: '0 auto', marginTop: '1rem' }} className='logoFormat' src={require('../images/auctionsbyrobyn2023.png')} />

        <div style={{
          display: 'block',
          textAlign: 'center',
          marginBottom: '1rem',
          marginTop: '1rem',
          fontWeight: 'bold',
          fontSize: '30pt'
        }}>
          Portal Dashboard
        </div>
        <div className='subscriberCount'>{textSubs}</div>
        <div className='subscriberText'>Text Notification Subscribers</div>
        <div className='subscriberCount'>{emailSubs}</div>
        <div className='subscriberText'>Email Notification Subscribers</div>


        {/* Main Dashboard*/}
        <div ref={dashboard} style={{ display: 'block' }}>
          <div className='dashboardSectionTitle'>Create/Delete Listing</div>
          <hr className="solid"></hr>

          <button disabled={disable} className='createNewListingButton' onClick={createNewListing}>+ Create New Listing</button>
          <div style={{ overflowX: 'scroll', maxWidth: '100vw' }}>
            <div className="gridHeader">
              <div></div>
              <div>Date</div>
              <div>Start Time</div>
              <div>Title</div>
              <div>Description</div>
            </div>

            <PortalList listing={currentListings} deleteListing={removeListing} disable={disable}></PortalList>
          </div>

          <div className='dashboardSectionTitle'>Send Notification/Banner Update</div>
          <hr className="solid"></hr>

          {/* Set Banner*/}
          <div className='bannerBoxSection'>
            <div className='bannerInputTitle'>Banner Title</div>
            <input ref={bannerTitle} type="text" className='bannerInputTitle'></input>
            <div className='bannerInputTitle'>Banner Description</div>
            <textarea ref={bannerDescription} className='bannerInputDescription' type="text" ></textarea>
            <div className='bannerInputTitle'>Date on which the banner will be removed:</div>
            <DatePicker className="bannerInputExpire" selected={expireBannerDate} onChange={(date) => setExpireBannerDate(date)} />
            <button disabled={disable} style={{ marginLeft: '0rem' }} className='createNewListingButton' onClick={confirmBanner}>Update Banner</button>
          </div>

          <button disabled={disable} className='logoutButton' onClick={exitDashboard}>Logout</button>

          <ConfirmBanner display={confirmBannerWindow} submit={postBanner} cancel={cancelBanner}></ConfirmBanner>
        </div>

        {/* Create new listing*/}
        <div ref={createListing} style={{ display: "none" }}>
          <form>

            <div className='newListingHeader'>Create New Listing</div>
            < button disabled={disable} className='backButton' onClick={exitNewListing}>Back</button>
            <div style={{ color: 'green' }} className='inputLabels'>Start date of Auction</div>
            <DatePicker className="dateInput" selected={startDate} onChange={(date) => setStartDate(date)} />
            <div style={{ color: 'green' }} className='inputLabels'>Start time of Auction</div>
            <TimePicker className="timeInput" onChange={onChange} value={value} />

            <div className='inputLabels'>Auction Type</div>
            <select className="timeInput" ref={auctionType}>
              <option value="online">Online Only</option>
              <option value="live">Live Only</option>
              <option value="liveOnline">Live and Online</option>
            </select>
            <div className='inputLabels'>Auction Title</div>
            <input ref={auctionTitle} className='titleInput' type="text" name="title" placeholder='Title'></input>
            <div className='inputLabels'>Auction Description</div>
            <textarea ref={auctionDescription} className='textArea' type="text" name="description" placeholder='Description'></textarea>
            <div className='warning'>WARNING: Be sure to add the Auction Bill as the first picture!</div>
            <div className='button-wrap'>
              <label className='new-button' htmlFor='upload'>Select Picture</label>
              <input disabled={disable} id='upload' type='file' onChange={selectPictureHandler}></input>
            </div>
            <PortalPictureList listings={selectedPicState} remove={removePictureHandler} picDescription={updateImageDescription}></PortalPictureList>

            <button disabled={disable} style={{ backgroundColor: 'green' }} className='backButton' onClick={confirmPosting}>Submit Listing</button>
          </form>

          <Confirmation display={confirmPostWindow} submit={uploadListing} cancel={cancelPosting}></Confirmation>
        </div>


      </div>
    </div>
  )
}