import React from 'react'
import { Link } from 'react-router-dom';
import '../common.css'
import AuctionListing from './auctionListing.js'

export default function AuctionList({listing, nextImage, prevImage, openPic, closePic}) {
    return (
        listing.map(listing => {
            return  <AuctionListing key={listing.id} listing={listing} nextImage={nextImage} prevImage={prevImage} closePic={closePic} openPic={openPic}/>
        })
    )
}
